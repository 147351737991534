<template>
  <flixDashboard>
    <template v-slot:content>
      <webinars />
    </template>
  </flixDashboard>
</template>

<script>
export default {
  components: {
    webinars () { return import('@/components/webinars/jitsi/list') }
  },
  props: {},
  mounted () {
  },
  computed: {

  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
